@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap");

@layer components {
  .containerRest {
    @apply flex h-screen w-screen bg-gradient-to-b to-blue-500 from-cyan-200 flex-col text-white font-quicksand justify-evenly items-center text-center relative;
  }
  .containerWork {
    @apply flex h-screen w-screen bg-gradient-to-b to-red-500 from-pink-200 flex-col text-white font-quicksand justify-evenly items-center text-center relative;
  }
}

.App {
  height: 100vh;
  width: 100vw;
}
